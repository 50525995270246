import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { SeekerConnectSuccessComponent } from './seeker-connect-success.component';

@NgModule({
  declarations: [SeekerConnectSuccessComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [SeekerConnectSuccessComponent],
})
export class SeekerConnectSuccessModule {}
