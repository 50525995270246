import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import {
  ActivityDTO,
  GetActivityResponseDTO,
  RejectAccessRequestResponseDTO,
} from '@api/models/activities-dto';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  private readonly apiURL = environment.apiURL;

  constructor(private http: HttpClient) {}

  public getActivities(): Observable<ActivityDTO[]> {
    return this.http
      .get<GetActivityResponseDTO>(`${this.apiURL}/api/activities`)
      .pipe(map(({ activities }) => activities));
  }

  public pingAssetDetails(id: string): Observable<unknown> {
    return this.http.post(`${this.apiURL}/api/activities/${id}/view_asset`, null);
  }

  public rejectAccessRequest(id: string): Observable<ActivityDTO> {
    return this.http
      .post<RejectAccessRequestResponseDTO>(
        `${this.apiURL}/api/activities/${id}/reject_request`,
        null
      )
      .pipe(map(({ activities }) => activities[0]));
  }
}
