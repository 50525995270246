import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor() {}

  public getFileSize(size: number): string {
    if (size > 0 && size < 1024) {
      return size + 'bytes';
    } else if (size > 1024 && size < 1048576) {
      return (size / 1024).toFixed(1) + 'Kb';
    } else if (size > 1048576) {
      return (size / 1048576).toFixed(1) + 'Mb';
    } else {
      return '';
    }
  }
}
