import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import {
  ActivateAgreementResponseDTO,
  AgreementDTO,
  CreateAgreementResponseDTO,
  DeactivateAgreementResponseDTO,
  GetAgreementsResponseDTO,
  GetSignaturesResponseDTO,
  SignAgreementResponseDTO,
} from '@api/models/agreements';

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  private readonly apiURL = environment.apiURL;

  constructor(private http: HttpClient) {}

  public getAgreements(): Observable<AgreementDTO[]> {
    return this.http
      .get<GetAgreementsResponseDTO>(`${this.apiURL}/api/agreements`)
      .pipe(map(({ agreements }) => agreements));
  }

  public activateAgreement(id: string): Observable<AgreementDTO> {
    return this.http
      .post<ActivateAgreementResponseDTO>(`${this.apiURL}/api/agreements/${id}/activate`, null)
      .pipe(map(({ agreements }) => agreements[0]));
  }

  public signAgreement(id: string): Observable<AgreementDTO> {
    return this.http
      .post<SignAgreementResponseDTO>(`${this.apiURL}/api/agreements/${id}/sign`, null)
      .pipe(map(({ agreements }) => agreements[0]));
  }

  public deactivateAgreement(id: string): Observable<AgreementDTO> {
    return this.http
      .post<DeactivateAgreementResponseDTO>(`${this.apiURL}/api/agreements/${id}/deactivate`, null)
      .pipe(map(({ agreements }) => agreements[0]));
  }

  public createAgreement(agreement: AgreementDTO): Observable<AgreementDTO> {
    return this.http
      .post<CreateAgreementResponseDTO>(`${this.apiURL}/api/agreements`, { agreement })
      .pipe(map(({ agreements }) => agreements[0]));
  }

  public getSignatures(): Observable<AgreementDTO[]> {
    return this.http
      .get<GetSignaturesResponseDTO>(`${this.apiURL}/api/signatures`)
      .pipe(map(({ agreements }) => agreements));
  }
}
